<template>
    <EmpleadoDt  :Mode="null"/>
</template>


<script>

import EmpleadoDt from '@/components/Generales/EmpleadoDt'
export default {
    name:  "Empleado",
    data(){
        return{

        }
    },

    components : {
        EmpleadoDt
    }
}
</script>